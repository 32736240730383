* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 20px;
  margin-top: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  background-image: url(/public/photo-1579546929518-9e396f3cc809.avif);
  background-size: cover;
  margin-top: 5%;
  align-items: center;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-left: 20px;
}


.square {
  background: #fff;
  border: 2px solid #6F646F;
  float: left;
  font-weight: bold;
  line-height: 34px;
  height: 95px;
  margin: 1px;
  padding: 0;
  text-align: center;
  width: 95px;
  border-radius: 21%;
  color: #F4346A;
  font-size: 250%;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
  color: #3E333E;
  font-size: 190%;
  font-weight: bold;
  text-shadow: 0.5px 0.5px white;
}

.game {
  display: flex;
  flex-direction: row;
  margin: 10px;
  background: #6F646F;
  padding: 40px;
  border-radius: 9%;
}

.game-info {
  margin-left: 20px;
  color: #3E333E;
  text-shadow: 0.2px 0.2px white;

}

button {
  color: #3E333E;
  background-color: white;
  padding: 5px 20px;
  border-radius: 43%;
  width: 177px;
  border: 2px solid #6F646F;
  font-weight: bold;
}